import {
  PropertyCardSubtitleType,
  PropertyCardBadgeType,
  OwnerPropertyCardType,
  PropertyActiveProcessCardType,
} from "@/enums/PropertyCardType";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "@/../tailwind.config.js";
import {
  IPropertyStatus,
  IPropertyActiveProcessStatus,
  IPropertyStatusSubtitle,
  IPropertyStatusBadge,
} from "@/models/Status";

const tailwindColors = resolveConfig(tailwindConfig).theme.colors;

/* eslint-disable no-unused-vars */
export const OWNER_PROPERTY_CARD_TYPE: {
  [key in OwnerPropertyCardType]: IPropertyStatus;
} = {
  [OwnerPropertyCardType.ONBOARDING]: {
    title: "common.adPublication",
    subtitle: "common.completePendingTasks",
    subtitleType: PropertyCardSubtitleType.ALERT,
  },
  [OwnerPropertyCardType.PUBLISHED]: {
    title: "common.publishedAd",
    badgeType: PropertyCardBadgeType.RENT,
    badgeText: "common.forRent",
  },
  [OwnerPropertyCardType.RESERVATION]: {
    title: "common.signingOfNewTenantContract",
    subtitle: "common.followTheProgressWithYourAgent",
    subtitleType: PropertyCardSubtitleType.INFO,
  },
  [OwnerPropertyCardType.IN_MANAGEMENT]: {
    subtitle: "common.fromDate",
    needsDateInfo: true,
    badgeType: PropertyCardBadgeType.SUCCESS,
    badgeText: "common.rented",
  },
  [OwnerPropertyCardType.CAPTURED_WITH_ACTIVE_LEASING]: {
    title: "common.youHaveActiveProcesses",
    subtitle: "common.completePendingTasks",
    subtitleType: PropertyCardSubtitleType.ALERT,
  },
  [OwnerPropertyCardType.PUBLISHED_WITH_ACTIVE_LEASING]: {
    title: "common.youHaveActiveProcesses",
    subtitle: "common.followTheProgressWithYourAgent",
    subtitleType: PropertyCardSubtitleType.INFO,
  },
  [OwnerPropertyCardType.PENDING_SIGNATURE]: {
    title: "common.youHaveActiveProcesses",
    subtitle: "common.followTheProgressWithYourAgent",
    subtitleType: PropertyCardSubtitleType.INFO,
  },
  [OwnerPropertyCardType.RESIGNATION]: {
    title: "common.resignationOfTheCurrentTenant",
    subtitle: "common.followTheProgressWithYourAgent",
    subtitleType: PropertyCardSubtitleType.INFO,
  },
  [OwnerPropertyCardType.NOT_RENTED]: {
    subtitle: "common.fromDate",
    needsDateInfo: true,
    badgeText: "common.notRented",
  },
  [OwnerPropertyCardType.CANCELLED]: {
    needsDateInfo: true,
    badgeText: "common.cancelled",
  },
  [OwnerPropertyCardType.PENDING_RECOVER]: {
    title: "common.adPublication",
    subtitle: "common.weWillPublishItAsSoonAsPossible",
    subtitleType: PropertyCardSubtitleType.INFO,
  },
  [OwnerPropertyCardType.PENDING_RECOVER_FUTURE_DATE]: {
    title: "common.adPublication",
    subtitle: "common.plannedPublicationX",
    subtitleType: PropertyCardSubtitleType.INFO,
    needsDateInfo: true,
  },
};

export const PROPERTY_ACTIVE_PROCESS_CARD_TYPE: {
  [key in PropertyActiveProcessCardType]: IPropertyActiveProcessStatus;
} = {
  [PropertyActiveProcessCardType.RESIGNATION]: {
    title: "common.resignationOfTheCurrentTenant",
    subtitle: "common.followTheProgressWithYourAgent",
    subtitleType: PropertyCardSubtitleType.INFO,
  },
  [PropertyActiveProcessCardType.CAPTURED]: {
    title: "common.adPublication",
    subtitle: "common.completePendingTasks",
    subtitleType: PropertyCardSubtitleType.ALERT,
    clickable: true,
  },
  [PropertyActiveProcessCardType.CAPTURED_NOT_CLICKABLE]: {
    title: "common.adPublication",
    subtitle: "common.completePendingTasks",
    subtitleType: PropertyCardSubtitleType.ALERT,
  },
  [PropertyActiveProcessCardType.PUBLISHED]: {
    title: "common.publishedAd",
    subtitle: "common.seePerformance",
    badgeType: PropertyCardBadgeType.RENT,
    badgeText: "common.forRent",
    clickable: true,
  },
  [PropertyActiveProcessCardType.PENDING_SIGNATURE]: {
    title: "common.signingOfNewTenantContract",
    subtitle: "common.followTheProgressWithYourAgent",
    subtitleType: PropertyCardSubtitleType.INFO,
  },
  [PropertyActiveProcessCardType.IN_MANAGEMENT]: {
    badgeType: PropertyCardBadgeType.SUCCESS,
    badgeText: "common.rented",
    infoText: {
      date: {
        key: "common.effectiveFrom",
        value: "",
      },
      price: {
        key: "common.monthlyRent",
        value: "",
      },
      plan: {
        key: "common.contractedPlan",
        value: "common.unmanaged",
      },
      tenantNames: {
        key: "common.tenants",
        value: [],
      },
    },
  },
  [PropertyActiveProcessCardType.NOT_RENTED]: {
    badgeText: "common.notRented",
    infoText: {
      date: {
        key: "common.notRentedSince",
        value: "",
      },
      plan: {
        key: "common.contractedPlan",
        value: "common.integralManagement",
      },
    },
  },
  [PropertyActiveProcessCardType.CANCELLED]: {
    badgeText: "common.cancelled",
    infoText: {
      date: {
        key: "common.rentCancelledOn",
        value: "",
      },
    },
  },
  [PropertyActiveProcessCardType.PENDING_RECOVER]: {
    title: "common.adPublication",
    subtitle: "common.weWillPublishItAsSoonAsPossible",
    subtitleType: PropertyCardSubtitleType.INFO,
  },
  [PropertyActiveProcessCardType.PENDING_RECOVER_FUTURE_DATE]: {
    title: "common.adPublication",
    subtitle: "common.plannedPublicationX",
    subtitleType: PropertyCardSubtitleType.INFO,
    needsDateInfo: true,
  },
};

export const PROPERTY_CARD_SUBTITLE_TYPE: {
  [key in PropertyCardSubtitleType]: IPropertyStatusSubtitle;
} = {
  [PropertyCardSubtitleType.ALERT]: {
    icon: "icons/alert",
    class: "text-yellow70",
    iconColor: tailwindColors.yellow70,
  },
  [PropertyCardSubtitleType.INFO]: {
    icon: "icons/info",
    class: "text-blue70",
    iconColor: tailwindColors.blue70,
  },
  [PropertyCardSubtitleType.DEFAULT]: {
    icon: "",
    class: "text-gray40",
    iconColor: "",
  },
};

export const PROPERTY_CARD_BADGE_TYPE: {
  [key in PropertyCardBadgeType]: IPropertyStatusBadge;
} = {
  [PropertyCardBadgeType.RENT]: {
    icon: "icons/rent-home",
    class: "text-white font-bold rent",
    iconClass: "mr-4",
  },
  [PropertyCardBadgeType.SUCCESS]: {
    icon: "icons/success",
    class: "text-white font-bold bg-green60 pr-8 pl-5",
    iconClass: "mr-5",
  },
  [PropertyCardBadgeType.DEFAULT]: {
    icon: "",
    class: "text-gray70 cancelled",
    iconClass: "",
  },
};
